import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import { createBrowserHistory } from "history";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Accueil } from "./pages/Accueil";
import { Contact } from "./pages/Contact";

const history = createBrowserHistory();

function App() {

    document.title = "ALIMHA | Demo";


    return (
        <React.Fragment>
            <div className="App">
                <Router history={history}>
                    <Navbar/>
                    <Switch>
                        <Route exact path="/success">
                            <Contact/>
                        </Route>
                        <Route exact path="/">
                            <Accueil/>
                        </Route>
                    </Switch>
                    <Footer/>
                </Router>
            </div>
            {/*<ToastContainer/>*/}
        </React.Fragment>
    );
}

export default App;
